import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { styled } from '@mui/material'
import MuiFormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  getTestIdProp
} from '../../../../commonFunc'

const Root = styled(Grid)({
  padding: '25px'
})

const FormControl = styled(MuiFormControl)({
  padding: '0 0 0 25px'
})

Parameters.errorMessages = {
  observationDateRequired: 'Observation Date Required',
  periodStartDateRequired: 'Period Start Date Required',
  periodEndDateRequired: 'Period End Date Required'

}

Parameters.fieldNames = {
  observationDate: 'observationDate',
  periodStartDate: 'periodStartDate',
  periodEndDate: 'periodEndDate',
  applyIncidenceRateCap: 'applyIncidenceRateCap',
  incidenceRate: 'incidenceRate',
  addMonthlyAggregation: 'addMonthlyAggregation',
  applySubstandardFlatExtra: 'applySubstandardFlatExtra',
  addBuhlmannConstants: 'addBuhlmannConstants',
  applyExpectedLapseRates: 'applyExpectedLapseRates',
  includeWarnings: 'includeWarnings',
  calculateIbnr: 'calculateIbnr',
  performMonteCarlo: 'performMonteCarlo',
  treatyRestrictions: 'treatyRestrictions',
  policyRestrictions: 'policyRestrictions'
}

Parameters.inputTestIds = {
  observationDate: 'textfield-observationDate',
  periodStartDate: 'textfield-periodStartDate',
  periodEndDate: 'textfield-periodEndDate',
  applyIncidenceRateCap: 'switch-applyIncidenceRateCap',
  incidenceRate: 'textfield-incidenceRate',
  addMonthlyAggregation: 'switch-addMonthlyAggregation',
  applySubstandardFlatExtra: 'switch-applySubstandardFlatExtra',
  addBuhlmannConstants: 'switch-addBuhlmannConstants',
  applyExpectedLapseRates: 'switch-applyExpectedLapseRates',
  includeWarnings: 'switch-includeWarnings',
  calculateIbnr: 'switch-calculateIbnr',
  performMonteCarlo: 'switch-performMonteCarlo',
  treatyRestrictions: 'textfield-treatyRestrictions',
  policyRestrictions: 'textfield-policyRestrictions'
}

export default function Parameters (props) {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch
  } = useFormContext()

  const testIdProp = getTestIdProp(props);
  const showIncidentRateField = watch(Parameters.fieldNames.applyIncidenceRateCap)
  const handleApplyIncidenceRateCap = (event, value) => {
    const incidenceRate = event.target.checked ? 500 : -1
    const shouldTouch = true; //  Triggers onChange
    setValue(Parameters.fieldNames.incidenceRate, incidenceRate, { shouldTouch });
    setValue(Parameters.fieldNames.applyIncidenceRateCap, value, { shouldTouch });
  }

  return (
    <Root container spacing={3} {...testIdProp}>
      <Grid container item xs={6} spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6'>Observation Date</Typography>
              <TextField
              {...register(Parameters.fieldNames.observationDate, { required: Parameters.errorMessages.observationDateRequired })}
              inputProps={{
                'data-testid': Parameters.inputTestIds.observationDate
              }}
              label=' '
              type='date'
              variant='standard'
              disabled={!props.access}
              error={!!errors.observationDate}
              helperText={errors.observationDate && errors.observationDate.message}
            />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>Study Period Start</Typography>

          <TextField
            {...register(Parameters.fieldNames.periodStartDate, { required: Parameters.errorMessages.periodStartDateRequired })}
            inputProps={{
              'data-testid': Parameters.inputTestIds.periodStartDate
            }}
            label=' '
            type='date'
            variant='standard'
            disabled={!props.access}
            error={!!errors.periodStartDate}
            helperText={errors.periodStartDate && errors.periodStartDate.message}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>Study Period End</Typography>

          <TextField
            {...register(Parameters.fieldNames.periodEndDate, { required: Parameters.errorMessages.periodEndDateRequired })}
            inputProps={{
              'data-testid': Parameters.inputTestIds.periodEndDate
            }}
            label=' '
            type='date'
            variant='standard'
            disabled={!props.access}
            error={!!errors.periodEndDate}
            helperText={errors.periodEndDate && errors.periodEndDate.message}
          />
        </Grid>
      </Grid>

      <Grid container item xs={6} spacing={3}>
        <FormControl component='fieldset'>
          <FormGroup>
            <Controller
              name={Parameters.fieldNames.applyIncidenceRateCap}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch
                            {...field}
                            checked={!!field.value}
                            onChange={handleApplyIncidenceRateCap}
                            inputProps={{
                              'data-testid': Parameters.inputTestIds.applyIncidenceRateCap
                            }}
                            />}
                  label='Apply Per Thousand Incidence Rate Cap'
                />
              )}
            />
            {showIncidentRateField && <div>
              <TextField
                {...register(Parameters.fieldNames.incidenceRate)}
                inputProps={{
                  'data-testid': Parameters.inputTestIds.incidenceRate
                }}
                type='number'
                error={!!errors.incidenceRate}
                helperText={errors.incidenceRate && errors.incidenceRate.message}
              />
            </div>}
            <Controller
              name={Parameters.fieldNames.addMonthlyAggregation}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                label='Apply Monthly Aggregation'
                control={<Switch
                  {...field}
                  checked={!!field.value}
                  inputProps={{
                    'data-testid': Parameters.inputTestIds.addMonthlyAggregation
                  }}
                  color='primary'
                />}
              />
              )}
            />
            <Controller
              name={Parameters.fieldNames.applySubstandardFlatExtra}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                label='Apply Substandard Flat Extra'
                control={<Switch
                  {...field}
                  checked={!!field.value}
                  inputProps={{
                    'data-testid': Parameters.inputTestIds.applySubstandardFlatExtra
                  }}
                  color='primary'
                />}
              />
              )}
            />
            <Controller
              name={Parameters.fieldNames.addBuhlmannConstants}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                label='Apply Buhlmann Constants'
                control={<Switch
                  {...field}
                  checked={!!field.value}
                  inputProps={{
                    'data-testid': Parameters.inputTestIds.addBuhlmannConstants
                  }}
                  color='primary'
                />}
              />
              )}
            />
            <Controller
              name={Parameters.fieldNames.applyExpectedLapseRates}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                label='Apply Expected Lapse Rates'
                control={<Switch
                  {...field}
                  checked={!!field.value}
                  inputProps={{
                    'data-testid': Parameters.inputTestIds.applyExpectedLapseRates
                  }}
                  color='primary'
                />}
              />
              )}
            />
            <Controller
              name={Parameters.fieldNames.includeWarnings}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                label=' Include Warnings'
                control={<Switch
                  {...field}
                  checked={!!field.value}
                  inputProps={{
                    'data-testid': Parameters.inputTestIds.includeWarnings
                  }}
                  color='primary'
                />}
              />
              )}
            />
              <Controller
              name={Parameters.fieldNames.calculateIbnr}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                label='Calculate IBNR'
                control={<Switch
                  {...field}
                  checked={!!field.value}
                  inputProps={{
                    'data-testid': Parameters.inputTestIds.calculateIbnr
                  }}
                  color='primary'
                />}
              />
              )}
            />
            <Controller
              name={Parameters.fieldNames.performMonteCarlo}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                label='Perform Monte Carlo'
                control={<Switch
                  {...field}
                  checked={!!field.value}
                  inputProps={{
                    'data-testid': Parameters.inputTestIds.performMonteCarlo
                  }}
                  color='primary'
                />}
              />
              )}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Treaty Restrictions

          <Tooltip
            title="use 'and vt.' before all column names. (e.g. and vt.source = 'GARE' and vt.treaty# = 123)"
          >
            <span><InfoOutlinedIcon /></span>
          </Tooltip>
        </Typography>

        <TextField
          {...register(Parameters.fieldNames.treatyRestrictions)}
          inputProps={{
            'data-testid': Parameters.inputTestIds.treatyRestrictions
          }}
          variant="outlined"
          placeholder="use 'and vt.' before all column names. (e.g. and vt.source = 'GARE' and vt.treaty# = 123)"
          fullWidth
          error={!!errors.treatyRestrictions}
          helperText={errors.treatyRestrictions && errors.treatyRestrictions.message}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">
          Policy Restrictions

          <Tooltip
            title="use 'and vp.' before all column names. (e.g. and vp.policyid = 123 and vp.issue_age between 18 and 65) "
          >
            <span><InfoOutlinedIcon /></span>
          </Tooltip>
        </Typography>

        <TextField
          {...register(Parameters.fieldNames.policyRestrictions)}
          inputProps={{
            'data-testid': Parameters.inputTestIds.policyRestrictions
          }}
          name='policyRestrictions'
          variant="outlined"
          placeholder="use 'and vp.' before all column names. (e.g. and vp.policyid = 123 and vp.issue_age between 18 and 65)"
          fullWidth
          error={!!errors.policyRestrictions}
          helperText={errors.policyRestrictions && errors.policyRestrictions.message}
        />
      </Grid>
    </Root>
  )
}

Parameters.propTypes = {
  access: PropTypes.any,
  name: PropTypes.string
}
