import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SaveIcon from '@mui/icons-material/Save'
import DownloadIcon from '@mui/icons-material/Download'
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined'
import BaseCreatableSelect from 'react-select/creatable'
import Filters from './Filters'
import OrderBy from './OrderBy'
import ResultsGrid from '../../../components/sql/resultsGridAG'
import Messages from '../../../components/sql/messages'
import FormulaDialog from '../../../components/sql/FormulaDialog'
import SaveQueryDialog from '../components/SaveQueryDialog'
import Navigation from '../components/navigation'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { downloadQuery } from '../../../store/pages/common/dataQuery'
import { DataPrepServer } from '../../../store/pages/common/agGRid/agGridServers'
import { getQueryDataBase } from '../../../store/pages/common/query'
import { QUERY_TYPE } from '../../../queryType'
import { FormProvider, useForm, Controller } from 'react-hook-form';

import {
  OPTION_EMPTY,
  OPTION_ALL,
  OPTION_FORMULA_BUILDER
} from '../../../stringConstants'

import {
  actions as formulaActions
} from '../../../store/pages/common/formulaDialog'

import {
  saveQuery,
  setLoadedQuery
} from '../../../store/pages/dataPrep'

import {
  MultiValueLabel,
  MultiValueRemove,
  MultiValueStyles
} from '../../../components/sql/SelectMultiValue'

const PAGE_SIZE = 20

const COMPONENTS_MULTI = {
  MultiValueLabel,
  MultiValueRemove
}

const Panel = styled(Grid)(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(4),
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  backgroundColor: '#DEDEDE'
}))

const CreatableSelect = styled(BaseCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const ButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: 'auto auto auto 1fr'
}))

const MODEL = {
  guid: '',
  queryType: 'query',
  name: '',
  description: '',
  data: {
    orderBy: null,
    orderByConditions: [],
    selectFields: [OPTION_ALL],
    filters: []
  }
}

const formStateIsValid = (formState) => {
  return formState.isValid || (Object.keys(formState.errors).length === 0 && !formState.isValid);
}

const getMultiSelectValue = (formValue, event) => {
  switch (event.action) {
    case 'select-option':
      return event.value

    case 'create-option':
      return [
        ...formValue,
        ...event.value
          .filter(item => item.__isNew__)
          .map(item => {
            const { __isNew__, ...rest } = item

            return rest
          })
      ]

    case 'remove-value':
      return formValue.filter(item => item.value !== event.removedValue.value)

    case 'clear':
      return []
  }
}

function Query () {
  const dispatch = useDispatch()
  const gridRef = React.useRef()
  const [querying, setQuerying] = React.useState(false)
  const [execute, setExecute] = React.useState(null)
  const [gridKey, setGridKey] = React.useState(0)
  const [formulaEditParams, setFormulaEditParams] = React.useState(null)
  const [saveQueryModel, setSaveQueryModel] = React.useState(null)

  const queryForm = useForm({ mode: 'onChange', defaultValues: MODEL });
  const navigation = useSelector(store => store.navigation)
  const project = useSelector(store => store.project)
  const query = useSelector(store => store.query)
  const dataQuery = useSelector(store => store.dataQuery)
  const dataPrep = useSelector(store => store.dataPrep)

  const columnNames = React.useMemo(() =>
    navigation.file.columns.map(column => column.Name),
  [navigation.file.columns])

  const columnOptions = React.useMemo(() => {
    return navigation.file.columns.map(column => ({
      label: `${column.Name} (${column.Type})`,
      value: column.Name
    }))
  }, [navigation.file.columns])

  const selectFieldsOptions = React.useMemo(() => [
    OPTION_ALL,
    OPTION_FORMULA_BUILDER,
    ...columnOptions
  ], [columnOptions])

  const filtersOptions = React.useMemo(() => [
    OPTION_FORMULA_BUILDER,
    ...columnOptions
  ], [columnOptions])

  const loadedQuery = dataPrep.loadedQueries[QUERY_TYPE.QUERY] || MODEL
  const loaded = !!loadedQuery.guid
  const saveLabel = loaded ? 'UPDATE' : 'SAVE'
  const redshiftTableName = `red_${project.id}_upload.${navigation.file.guid}`

  const downloadLabel = dataQuery.isDownloading
    ? 'Downloading...'
    : 'Download Results CSV'

  const downloadButtonIcon = dataQuery.isDownloading
    ? <CircularProgress
        data-testid='progress-download'
        size={20}
      />
    : <DownloadIcon
        data-testid='icon-play'
      />

  const saveButtonIcon = loaded
    ? <UpdateOutlinedIcon
        data-testid='icon-update'
      />
    : <SaveIcon
        data-testid='icon-save'
      />

  const getEditIndex = (name, value) => {
    if (name === 'data.selectFields') {
      const queryFormValues = queryForm.getValues()
      const selection = name.split('.').reduce((queryFormValues, key) =>
        queryFormValues?.[key] ?? undefined, queryFormValues)

      return selection.findIndex(option =>
        option.value === value.value
      )
    }

    return -1
  }

  const editFormula = (name, value) => {
    dispatch(formulaActions.setForm({
      name: value.label.replace('Formula (', '').replace(/\)$/, ''),
      formula: value.value
    }))

    setFormulaEditParams({
      name,
      selectedIndex: getEditIndex(name, value)
    })
  }

  const buildMultiSelectionFromFormula = updatedValue => {
    const index = formulaEditParams.selectedIndex
    const selectFields = queryForm.getValues('data.selectFields');

    const result = index !== -1
      ? [
          ...selectFields.slice(0, index),
          updatedValue,
          ...selectFields.slice(index + 1)
        ]
      : [
          ...selectFields,
          updatedValue
        ]

    return result.filter(item => (item.value !== OPTION_FORMULA_BUILDER.value) && (item.label !== OPTION_FORMULA_BUILDER.label))
  }

  const handleGridReady = params => {
    const formValues = queryForm.getValues();

    const dataSource = new DataPrepServer({
      hasMultipleOR: true,
      country: project.country,
      orderBy: formValues.data.orderBy,
      orderByConditions: formValues.data.orderByConditions,
      querySelectFields: formValues.data.selectFields,
      queryWhereConditions: formValues.data.filters
    }, gridRef, execute, dispatch, () => setQuerying(false))

    params.api.setGridOption('serverSideDatasource', dataSource)
  }

  const handleFormulaSave = result => {
    const formulaValue = {
      label: `Formula (${result.name})`,
      value: result.formula,
      type: 'formula'
    }

    const value = formulaEditParams.name === 'data.selectFields'
      ? buildMultiSelectionFromFormula(formulaValue)
      : formulaValue

    queryForm.setValue(formulaEditParams.name, value)

    setFormulaEditParams(null)
  }

  const handleFormulaCancel = () => {
    setFormulaEditParams(null)
  }

  const handleSaveQueryDialogSave = async dialogModel => {
    const builtQuery = {
      ...dialogModel,
      projectId: project.id,
      fileType: navigation.file.fileType,
      data: queryForm.getValues().data
    }

    const savedQuery = await dispatch(saveQuery(builtQuery))

    queryForm.reset(savedQuery)
    dispatch(setLoadedQuery(savedQuery, true))
    setSaveQueryModel(null)
  }

  const handleSaveQueryDialogCancel = () => {
    setSaveQueryModel(null)
  }

  const handleExecute = async () => {
    if (formStateIsValid(queryForm.formState)) {
      await queryForm.trigger()
    }

    if (formStateIsValid(queryForm.formState)) {
      setExecute({
        id: project.id,
        preCalc: false,
        page: 0,
        pagesOnPage: PAGE_SIZE,
        tableName: redshiftTableName
      })

      setGridKey(prev => prev + 1)
      setQuerying(true)
    }
  }

  const handleDownload = async () => {
    const formValues = queryForm.getValues()
    dispatch(
      downloadQuery(
        execute,
        formValues.data.orderBy,
        formValues.data.orderByConditions,
        formValues.data.selectFields,
        formValues.data.filters
      )
    )
  }

  const handleSaveButtonClick = () => {
    setSaveQueryModel(loadedQuery)
  }

  const handleFieldEdit = (value, event) => {
    editFormula('data.selectFields', value)
  }

  const handleConditionEdit = (name, value) => {
    editFormula(name, value)
  }

  const handleSelectFieldsChange = (value, event) => {
    if (
      event.action === 'select-option' &&
      event.option.value === 'formula'
    ) {
      return setFormulaEditParams({
        name: event.name,
        selectedIndex: -1
      })
    }

    const result = getMultiSelectValue(queryForm.getValues('data.selectFields'), {
      action: event.action,
      name: event.name,
      removedValue: event.removedValue,
      value
    })

    queryForm.setValue('data.selectFields', result)
  }

  const getSelectFieldsChangeHandler = (customOnChange, controllerOnChange) => {
    return (value, event) => {
      customOnChange(value, event);
      controllerOnChange(value, event);
    }
  }

  const handleFiltersChange = (name, value) => {
    if (value.value === 'formula') {
      setFormulaEditParams({
        name,
        selectedIndex: -1
      })
    } else if (value.value === OPTION_EMPTY.value) {
      queryForm.resetField(name);
    } else {
      queryForm.setValue(name, value);
    }
  }

  const handleSelectFieldKeyDown = event => {
    if (event.ctrlKey && event.keyCode === 13) {
      dispatch(getQueryDataBase(
        0,
        PAGE_SIZE,
        navigation.file.guid,
        project.id
      ))
    }
  }

  React.useEffect(() => {
    queryForm.reset(loadedQuery);
  }, [
    loadedQuery,
    queryForm
  ])

  React.useEffect(() => {
    return () => {
      dispatch(setLoadedQuery(queryForm.getValues(), true))
    }
  }, [
    queryForm,
    dispatch
  ])

  return (
    <div>
      <Dialog
        data-testid='dialog-formula-outer'
        fullWidth
        open={Boolean(formulaEditParams)}
        maxWidth='md'
      >
        <FormulaDialog
          data-testid='dialog-formula'
          tableName={redshiftTableName}
          resource={project}
          columnNames={columnNames}
          onSave={handleFormulaSave}
          onCancel={handleFormulaCancel}
        />
      </Dialog>

      {saveQueryModel && (
        <SaveQueryDialog
          data-testid='dialog-query'
          type={navigation.file.fileType}
          model={saveQueryModel}
          onSave={handleSaveQueryDialogSave}
          onCancel={handleSaveQueryDialogCancel}
        />
      )}

      <Grid item xs={12}>
        <Navigation/>

        <Panel>
          <Typography variant='h6'>Fields</Typography>
          <div data-testid='select-selectFields-container'>
            <Controller
                name={'data.selectFields'}
                control={queryForm.control}
                render={({ field }) => (
                  <CreatableSelect
                  {...field}
                  data-testid='select-selectFields'
                  styles={MultiValueStyles}
                  isMulti
                  isClearable
                  openMenuOnFocus
                  components={COMPONENTS_MULTI}
                  options={selectFieldsOptions}
                  onKeyDown={handleSelectFieldKeyDown}
                  onChange={getSelectFieldsChangeHandler(handleSelectFieldsChange, field.onChange)}
                  onEdit={handleFieldEdit}
                  aria-label="select-fields-input"
                />
                )}
              />
            </div>

            <FormProvider {...queryForm}>
              <Filters
                data-testid='filters'
                name="data.filters"
                columnOptions={filtersOptions}
                onChange={handleFiltersChange}
                onEdit={handleConditionEdit}
              />

              <OrderBy
                data-testid='order-by'
                name='data'
                columnOptions={columnOptions}
              />
            </FormProvider>
          <Grid item xs={12}>
            {query.resultsGrid.isLoading
              ? <CircularProgress
                  data-testid='progress-loading'
                />
              : <ButtonContainer>
                <Button
                  data-testid='button-execute'
                  disabled={querying || queryForm.formState.isValidating}
                  color='primary'
                  variant='outlined'
                  startIcon={<PlayArrowIcon />}
                  onClick={handleExecute}
                >Execute</Button>

                <Button
                  data-testid='button-download'
                  disabled={!execute || dataQuery.isDownloading}
                  color='primary'
                  variant='outlined'
                  startIcon={downloadButtonIcon}
                  onClick={handleDownload}
                >{downloadLabel}</Button>

                <Button
                  data-testid='button-save'
                  disabled={querying}
                  color='secondary'
                  variant='contained'
                  startIcon={saveButtonIcon}
                  onClick={handleSaveButtonClick}
                >{saveLabel}</Button>

                <div></div>
              </ButtonContainer>
            }
          </Grid>
        </Panel>

        <Grid>
          <Messages />

          {execute && (
            <ResultsGrid
              data-testid='grid'
              key={gridKey}
              gridRef={gridRef}
              execute={execute}
              onGridReady={handleGridReady}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(Query)
