import PropTypes from 'prop-types'
import React from 'react'
import Table from './Table'
import { styled } from '@mui/material'

const Text = styled('p')({
  margin: '0',
  padding: '0',
  display: 'inline',
  marginLeft: '3px'
})

function formatBoolean (v) {
  return v ? 'Yes' : 'No'
}

function formatDate (v) {
  return v || '-'
}

function formatTextList (v) {
  return v || 'None'
}

export default function ParametersTable (props) {
  const params = props.parameters
  const jobId = props.jobId ? props.jobId : 'current'

  const studyPeriodLabel = params.periodStartDate || params.periodEndDate
    ? [
        formatDate(params.periodStartDate),
        formatDate(params.periodEndDate)
      ].join(' to ')
    : '-'

  const data = [
    {
      id: 'observationDate',
      label: 'Observation Date',
      value: formatDate(params.observationDate)
    },
    {
      id: 'studyPeriod',
      label: 'Study Period',
      value: studyPeriodLabel
    },
    {
      id: 'applyIncidenceRateCap',
      label: 'Apply Incidence Rate Cap',
      value: formatBoolean(params.applyIncidenceRateCap)
    },
    {
      id: 'applyMonthlyAggregation',
      label: 'Apply Monthly Aggregation',
      value: formatBoolean(params.addMonthlyAggregation)
    },
    {
      id: 'applySubstandardFlatExtra',
      label: 'Apply Substandard Flat Extra',
      value: formatBoolean(params.applySubstandardFlatExtra)
    },
    {
      id: 'applyExpectedLapseRates',
      label: 'Apply Expected Lapse Rates',
      value: formatBoolean(params.applyExpectedLapseRates)
    },
    {
      id: 'treatyRestrictions',
      label: 'Treaty Restrictions',
      value: formatTextList(params.treatyRestrictions)
    },
    {
      id: 'policyRestrictions',
      label: 'Policy Restrictions',
      value: formatTextList(params.policyRestrictions)
    }
  ]

  return <Table
    title='Parameters'
    items={data.map(item =>
    <span key={item.id} data-testid={[item.id, jobId].join('-')}>
      <strong data-testid={`label-${item.id}`}>{item.label}:</strong>

      <Text data-testid={`value-${item.id}`}>{item.value}</Text>
    </span>
    )}
  />
}

ParametersTable.propTypes = {
  parameters: PropTypes.shape({
    applyIncidenceRateCap: PropTypes.bool,
    addMonthlyAggregation: PropTypes.bool,
    applySubstandardFlatExtra: PropTypes.bool,
    applyExpectedLapseRates: PropTypes.bool,
    treatyRestrictions: PropTypes.string,
    policyRestrictions: PropTypes.string,
    observationDate: PropTypes.string,
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string
  })
}

ParametersTable.defaultTypes = {
  parameters: {
    applyIncidenceRateCap: false,
    addMonthlyAggregation: false,
    applySubstandardFlatExtra: false,
    applyExpectedLapseRates: false,
    treatyRestrictions: '',
    policyRestrictions: '',
    observationDate: '',
    periodStartDate: '',
    periodEndDate: ''
  }
}
