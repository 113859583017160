import PropTypes from 'prop-types'
import React from 'react'
import ReactSelect from 'react-select'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material'
import { OPTION_EMPTY } from '../../../stringConstants'
import { useFormContext, Controller, useWatch } from 'react-hook-form'

import {
  SingleValue,
  SingleValueStyles
} from '../../../components/sql/SelectSingleValue'

import {
  MultiValueLabel,
  MultiValueRemove,
  MultiValueStyles
} from '../../../components/sql/SelectMultiValue'

const COMPONENTS_SINGLE = {
  SingleValue
}

const COMPONENTS_MULTI = {
  MultiValueLabel,
  MultiValueRemove
}

const OPTIONS_DIRECTION = [
  {
    value: 'asc',
    label: 'Asc'
  },
  {
    value: 'desc',
    label: 'Desc'
  }
]

const Grid = styled(Box)({
  display: 'grid',
  maxWidth: '800px',
  gridGap: '20px',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center'
})

const HeaderGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: 'auto 1fr'
}))

const FormControlLabel = styled(MuiFormControlLabel)({
  margin: 0
})

const Select = styled(ReactSelect)({
  minHeight: 58
})
export default function OrderBy (props) {
  const { control } = useFormContext();
  const enableOrderBy = useWatch({
    control,
    name: 'enableOrderBy'
  })

  return (
    <Grid>
      <HeaderGrid>
        <Typography variant='h6'>Order By</Typography>

        <Controller
          name='enableOrderBy'
          control={control}
          render={({ field }) => (
            <FormControlLabel
            label='Enable'
            control={<Switch
              {...field}
              size='small'
              checked={!!field.value}
              inputProps={{
                'data-testid': 'switch-enable'
              }}
            />}
          />
          )}
        />
      </HeaderGrid>

      <div></div>

      {enableOrderBy && (
        <>
          <div>
            <Typography variant='h6'>Columns</Typography>
            <Controller
              name={`${props.name}.orderByConditions`}
              control={control}
              render={({ field }) => (
                <Select
                {...field}
                data-testid='select-conditions'
                styles={MultiValueStyles}
                isDisabled={!enableOrderBy}
                isMulti
                components={COMPONENTS_MULTI}
                options={props.columnOptions}
                aria-label="select-order-by-conditions"
              />
              )}
            />

          </div>

          <div>
            <Typography variant='h6'>Direction</Typography>
            <Controller
              name={`${props.name}.orderBy`}
              control={control}
              render={({ field }) => (
                <Select
                {...field}
                data-testid='select-order'
                styles={SingleValueStyles}
                isDisabled={!enableOrderBy}
                isClearable
                components={COMPONENTS_SINGLE}
                options={OPTIONS_DIRECTION}
                aria-label="select-order-by"
              />
              )}
            />
          </div>
        </>
      )}
    </Grid>
  )
}

OrderBy.propTypes = {
  name: PropTypes.string,
  value: PropTypes.shape({
    enableOrderBy: PropTypes.bool,
    orderBy: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    orderByConditions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    )
  }),
  columnOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onChange: PropTypes.func
}

OrderBy.defaultProps = {
  name: '',
  value: {
    enableOrderBy: false,
    orderBy: OPTION_EMPTY,
    orderByConditions: []
  },
  columnOptions: [],
  onChange: (_name, _value) => {}
}
