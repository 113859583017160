import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkForProfilingData } from '../../../store/pages/project/async'

export default function useCheckIfFileIsProfiling () {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isLoaded = useSelector(store => store.global.isLoaded)
  const redirectToHome = useSelector(store => store.global.redirectToHome)

  React.useEffect(() => {
    if (redirectToHome) {
      navigate('/')
    } else if (isLoaded) {
      dispatch(checkForProfilingData)
    }
  }, [dispatch, isLoaded, redirectToHome, navigate])
}
