/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getJobHistory,
  getRediDefaultDates
} from '../../../store/pages/calculation/async'

import {
  forcedRefreshPostCalcCheckDataAction,
  getLapseData,
  getExpVsRiskAmountData,
  getExpVsRiskCountData,
  getNonTerminatingClaimsData,
  getNonTerminatingOvsQClaimsData,
  getPolicyTrackerData,
  getTerminatingClaimsData,
  getTerminatingOvsQClaimsData,
  getIbnrData,
  setSelectedJobIdAction
} from '../../../store/pages/postCalculation'
import calculationApi from '../../../store/objects/calculationApi'

export default () => {
  const dispatch = useDispatch()

  const {
    id,
    selectedJobId,
    studyType,
    forceRefresh,
    postCalculationCheckRunning,
    loadDates,
    latestRun,
    lastSuccessfulRunId,
    jobHistory,
    jobRuns
  } = useSelector(({
    project: {
      id,
      studyType,
      jobRuns
    },
    calculation: {
      loadDates,
      latestRun,
      jobHistory = null,
      lastSuccessfulRunId
    },
    postCalc: {
      selectedJobId,
      forceRefresh,
      postCalculationCheckRunning
    }
  }) => ({
    id,
    selectedJobId,
    studyType,
    forceRefresh,
    postCalculationCheckRunning,
    loadDates,
    latestRun,
    lastSuccessfulRunId,
    jobHistory,
    jobRuns
  }))

  const jobs = jobHistory?.map(calculationApi.formatJob)
  const selectedJob = jobs?.find(job => job.id === selectedJobId)

  React.useEffect(() => {
    let unmounted = false

    if (!unmounted && (
      !jobHistory || (latestRun && latestRun.ProjectId !== id)
    )) {
      dispatch(getJobHistory)
    }

    if (
      !postCalculationCheckRunning && (
        !unmounted && jobHistory && jobHistory.length > 0 && (
          (latestRun && latestRun.ProjectId !== id) ||
          forceRefresh ||
          selectedJobId !== 0
        )
      )
    ) {
      if (
        forceRefresh ||
        (selectedJobId !== 0 && forceRefresh) ||
        (
          latestRun.TableNames &&
          latestRun.TableNames.toLowerCase().includes('exp') &&
          latestRun.TableNames.toLowerCase().includes('val')
        )
      ) {
        dispatch({
          ...forcedRefreshPostCalcCheckDataAction,
          payload: {
            forceRefresh: false
          }
        })

        dispatch(getLapseData)
        dispatch(getExpVsRiskAmountData)
        dispatch(getExpVsRiskCountData)
        dispatch(getPolicyTrackerData)
        dispatch(getTerminatingClaimsData)
        dispatch(getNonTerminatingClaimsData)
        dispatch(getTerminatingOvsQClaimsData)
        dispatch(getNonTerminatingOvsQClaimsData)

        if (selectedJob && selectedJob.parameters.calculateIbnr) {
          dispatch(getIbnrData)
        }
      }
    }

    return () => {
      unmounted = true
    }
  }, [
    id,
    selectedJobId,
    forceRefresh,
    postCalculationCheckRunning,
    latestRun,
    jobHistory,
    jobRuns
  ])

  React.useEffect(() => {
    dispatch({
      ...setSelectedJobIdAction,
      payload: {
        selectedJobId: lastSuccessfulRunId
      }
    })
  }, [lastSuccessfulRunId, dispatch])

  React.useEffect(() => {
    const redi = studyType === 'redi' || studyType === 'skipMap'

    if (!loadDates && redi) {
      dispatch(getRediDefaultDates)
    }
  }, [
    loadDates,
    dispatch
  ])
}
