import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import { SingleValue } from '../../../components/sql/SelectSingleValue'
import { OPTION_EMPTY, OPERATIONS_OPTIONS } from '../../../stringConstants'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'

const DEFAULT_CONDITION = {
  operation: '',
  columnName: '',
  whereValue: ''
}

const COMPONENTS_SINGLE = {
  SingleValue
}

const MenuOption = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string
})

const ConditionValue = PropTypes.shape({
  operation: MenuOption,
  columnName: MenuOption,
  whereValue: MenuOption
})

const REQUIRED_MESSAGE = 'Required';

const FilterValue = PropTypes.arrayOf(ConditionValue)

const Root = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr'
}))

const GroupsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr auto',
  alignItems: 'start'
}))

const Group = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(4),
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  border: '1px solid #737373',
  borderRadius: '3px'
}))

const Row = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr 128px 1fr auto',
  alignItems: 'center'
}))

const OrSeparator = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  alignItems: 'center',
  gridColumn: '-1 / 1'
}))

function Condition (props) {
  const {
    control
  } = useFormContext()

  const gethandleSelectEdit = (fieldName) => {
    return (value) => {
      props.onEdit(fieldName, value)
    }
  }

  const getHandleSelectChange = (formOnChange, fieldName) => {
    return (value) => {
      formOnChange()
      props.onChange(fieldName, value || OPTION_EMPTY)
    }
  }

  return (
    <Row data-testid={props['data-testid']}>

      <Controller
        name={`${props.name}.columnName`}
        control={control}
        rules={{ required: REQUIRED_MESSAGE }}
        render={({ field }) => (
          <CreatableSelect
          {...field}
          data-testid={`select-columnName-${props.name}`}
          aria-label="select-filter-columnName"
          isClearable
          openMenuOnClick={!field.value}
          components={COMPONENTS_SINGLE}
          options={props.columnOptions}
          onEdit={gethandleSelectEdit(field.name)}
          onChange={(getHandleSelectChange(field.onChange, field.name))}
        />
        )}
      />

      <Controller
        name={`${props.name}.operation`}
        control={control}
        rules={{ required: REQUIRED_MESSAGE }}
        render={({ field }) => (
          <Select
          {...field}
          data-testid={`select-operation-${props.name}`}
          aria-label="select-filter-operation"
          isClearable
          options={OPERATIONS_OPTIONS}
          onChange={(getHandleSelectChange(field.onChange, field.name))}
        />
        )}
      />

      <Controller
        name={`${props.name}.whereValue`}
        control={control}
        rules={{ required: REQUIRED_MESSAGE }}
        render={({ field }) => (
          <CreatableSelect
          {...field}
          data-testid={`select-whereValue-${props.name}`}
          aria-label="select-filter-whereValue"
          isClearable
          openMenuOnClick={!field.value}
          components={COMPONENTS_SINGLE}
          options={props.columnOptions}
          onEdit={gethandleSelectEdit(field.name)}
          onChange={(getHandleSelectChange(field.onChange, field.name))}
        />
        )}
      />

      <IconButton
        data-testid={`button-remove-condition-${props.name}`}
        disabled={props.disableRemoveButton}
        size='small'
        onClick={props.onRemove}
      >
        <DeleteIcon />
      </IconButton>

      {props.showSeparator && <OrSeparator>OR</OrSeparator>}
    </Row>
  )
}

Condition.propTypes = {
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  disableRemoveButton: PropTypes.bool,
  showSeparator: PropTypes.bool,
  outerIndex: PropTypes.number,
  columnOptions: PropTypes.arrayOf(MenuOption),
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
}
function Filter (props) {
  const { fields, append, remove } = useFieldArray({
    name: props.name
  });

  const getHandleRemoveConditionClick = (index) => {
    return () => { remove(index) }
  }

  const handleAddConditionClick = () => {
    append(DEFAULT_CONDITION);
  }

  const handleEdit = (name, value) => {
    props.onEdit(name, value)
  }

  const handleChange = (fieldName, value) => {
    props.onChange(fieldName, value)
  }

  return (
    <GroupsContainer data-testid={props['data-testid']}>
      <Group>
        {fields.map((item, index) => (
          <Condition
            data-testid={`condition-${props.name}-${index}`}
            key={item.id}
            name={`${props.name}.${index}`}
            disableRemoveButton={fields.length === 1}
            showSeparator={index < fields.length - 1}
            columnOptions={props.columnOptions}
            onRemove={getHandleRemoveConditionClick(index)}
            onEdit={handleEdit}
            onChange={handleChange}
          />
        ))}

        <Box>
          <Button
            data-testid={`button-add-condition-${props.name}`}
            data-index={props.name}
            color='primary'
            size='medium'
            startIcon={<AddIcon />}
            onClick={handleAddConditionClick}
          >Add Condition</Button>
        </Box>
      </Group>

      <IconButton
        data-testid={`button-remove-filter-${props.name}`}
        size='small'
        onClick={props.onRemove}
      ><CloseIcon /></IconButton>
    </GroupsContainer>
  )
}

Filter.propTypes = {
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  columnOptions: PropTypes.arrayOf(MenuOption),
  value: FilterValue,
  onRemoveCondition: PropTypes.func,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
}

export default function Filters (props) {
  const { fields, append, remove } = useFieldArray({
    name: props.name
  });

  const handleAddFilterClick = () => {
    append([[DEFAULT_CONDITION]])
  }

  const getHandleRemoveFilter = index => {
    return () => {
      remove(index)
    }
  }

  const handleEdit = (name, value) => {
    props.onEdit(name, value)
  }

  const handleChange = (fieldName, value) => {
    props.onChange(fieldName, value)
  }

  return (
    <Root data-testid={props['data-testid']}>
      <Typography variant='h6'>Filters</Typography>

      {fields.length > 0
        ? fields.map((group, index) => (
          <GroupsContainer key={group.id}>
            <Filter
              data-testid={`filter-${index}`}
              name={`${props.name}.${index}`}
              columnOptions={props.columnOptions}
              onEdit={handleEdit}
              onChange={handleChange}
              onRemove={getHandleRemoveFilter(index)}
            />
          </GroupsContainer>
        ))
        : <Box
          data-testid='label-empty'
          sx={{ fontStyle: 'italic' }}
        >No filters present</Box>
      }

      <Box>
        <Button
          data-testid='button-add-filter'
          color='primary'
          startIcon={<AddIcon />}
          onClick={handleAddFilterClick}
        >Add Filter</Button>
      </Box>
    </Root>
  )
}

Filters.propTypes = {
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  columnOptions: PropTypes.arrayOf(MenuOption),
  value: PropTypes.arrayOf(FilterValue),
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  onAddCondition: PropTypes.func,
  onRemoveCondition: PropTypes.func,
  onChange: PropTypes.func,
  onEdit: PropTypes.func
}

/* istanbul ignore next */
Filters.defaultProps = {
  'data-testid': '',
  name: '',
  columns: [],
  value: [],
  onAddFilter: _name => {},
  onRemoveFilter: _name => {},
  onAddCondition: _name => {},
  onRemoveCondition: _name => {},
  onChange: (_name, _value) => {},
  onEdit: (_name, _value) => {}
}
