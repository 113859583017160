import React, { useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import Select from 'react-select'
import moment from 'moment'
import { updateColumnMapping } from '../../store/pages/mapping'
import { SingleValue, SingleValueStyles } from '../../components/sql/SelectSingleValue'

const ValueMappingComponent = ({ targetDataType, newM, validOption, relevantData, target, originalValue, access, dispatch }) => {
  const [inputValue, setInputValue] = useState(newM || '')

  // Dispatch Redux action only on blur
  const handleBlur = () => {
    if (inputValue !== newM) {
      dispatch(updateColumnMapping(relevantData, target, inputValue, originalValue))
    }
  }

  switch (targetDataType) {
    case 'OPTIONS':
      return (
        <div key={`opt-col-${target}-${newM}`}>
          <Select
            key={`opt-select-${target}-${newM}`}
            placeholder=" "
            isDisabled={!access}
            name={`opt-col-${target}-${newM}`}
            value={validOption.find(e => e.value === newM) || null}
            isClearable
            menuPortalTarget={document.querySelector('body')}
            menuPlacement='auto'
            options={validOption}
            styles={SingleValueStyles}
            components={{ SingleValue }}
            onChange={(newValue) => dispatch(updateColumnMapping(relevantData, target, newValue, originalValue))}
          />
        </div>
      )
    case 'NUMBER':
    case 'INT':
    case 'DECIMAL':
      return (
        <TextField
          disabled={!access}
          variant='standard'
          value={inputValue}
          id="standard-number"
          label="Number"
          type="number"
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    case 'VARCHAR':
      return (
        <TextField
          disabled={!access}
          label="Varchar"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: true
          }}
        />
      )
    case 'DATE':
      return (
        <DatePicker
          variant="outlined"
          disabled={!access}
          format="MM/dd/yyyy"
          value={newM ? new Date(newM) : null}
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          onChange={(newValue) => {
            const format = (relevantData.max && moment(relevantData.max).isValid())
              ? moment(relevantData.max).creationData().format + 'THH:mm:ss.SSSSZ'
              : 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
            const formatDate = moment(newValue).isValid() ? moment(newValue).format(format) : ''
            dispatch(updateColumnMapping(relevantData, target, formatDate, originalValue))
          }}
        />
      )
    default:
      return <div> {targetDataType} </div>
  }
}

export { ValueMappingComponent }
