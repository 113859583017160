import { client } from './apiHelper'
import delay from '../pages/common/delay'

const MAX_TRIES = 2

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

async function generateData (check, projectId, jobId, opts) {
  const {
    isMonthly,
    aggs,
    tableName
  } = opts

  const body = {
    check,
    projectId,
    jobId,
    isMonthly,
    aggs,
    tableName
  }

  let count = 0

  while (true) {
    try {
      return await client.post('/source/redshift', body, { region: __region })
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const fetchCheckData = (check) => async (projectId, jobId, opts = {}) => {
  return generateData(check, projectId, jobId, opts)
}

export default {
  generateData,
  fetchCheckData,
  setAPIRegion
}
