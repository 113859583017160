import { createStore, applyMiddleware, compose } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import { thunk } from 'redux-thunk'
import { createBrowserHistory } from 'history'
import rootReducer from './store/index'
import logger from 'redux-logger'

export const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() })

const enhancers = []
const middleware = [thunk, routerMiddleware, logger]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export const getStoreWithState = (preloadedState) => {
  return createStore(
    rootReducer(routerReducer),
    preloadedState,
    composedEnhancers
  )
}

export const store = createStore(
  rootReducer(routerReducer),
  composedEnhancers
)

export const history = createReduxHistory(store)
