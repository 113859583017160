import delay from '../pages/common/delay'
import { client } from './apiHelper'
import { QUERY_TYPE } from '../../queryType'

let __region = process.env.REACT_APP_REGION

const CHECKS_REDI = [
  {
    type: 'DUPLICATES',
    fileType: 'I'
  },
  {
    type: 'DUPLICATES',
    fileType: 'T'
  },
  {
    type: 'AAR',
    fileType: 'I'
  },
  {
    type: 'AAR',
    fileType: 'T'
  },
  {
    type: 'ESSENTIAL_FIELDS',
    fileType: 'I'
  },
  {
    type: 'ISSUE_AGE',
    fileType: 'I'
  },
  {
    type: 'ISSUE_AGE',
    fileType: 'T'
  },
  {
    type: 'TERM_ISSUE_DATE',
    fileType: 'T'
  },
  {
    type: 'LAPSE_SKEWNESS',
    fileType: 'T'
  },
  {
    type: 'MONTHLY_SKEWNESS',
    fileType: 'T'
  }
]

const CHECKS_DON_DOT_CHECK = [
  {
    type: 'DON_DOT_CHECK',
    fileType: 'T'
  }
]

// implementation details

const emptyObjectToNull = value => {
  return value && Object.keys(value).length ? value : null
}

const getRediChecks = (formatFiles, calcIBNR = false) => {
  const checks = CHECKS_REDI.reduce((accum, curr) => {
    const findFile = formatFiles.find(file => file.type === curr.fileType)

    if (findFile) {
      accum.push(curr)
    }

    return accum
  }, [])
  return calcIBNR ? [...checks, ...CHECKS_DON_DOT_CHECK] : checks;
}

const getPreCalCheck = (type, nonCriticalChecks, skipMapCheck, formatFiles, calcIBNR) => {
  switch (type) {
    case 'nonCritical':
      return nonCriticalChecks

    case 'skipMap':
      return skipMapCheck

    default:
      return getRediChecks(formatFiles, calcIBNR)
  }
}

const toModelFilters = raw => {
  const firstItem = {
    operation: raw.where[0].operation,
    columnName: raw.where[0].columnName,
    whereValue: raw.where[0].whereValue
  }

  const secondItem = {
    operation: raw.where[0].orOperation,
    columnName: raw.where[0].orColumnName,
    whereValue: raw.where[0].orWhereValue
  }

  const secondValid =
    emptyObjectToNull(secondItem.operation) &&
    emptyObjectToNull(secondItem.columnName) &&
    emptyObjectToNull(secondItem.whereValue)

  const filters = [
    firstItem,
    secondValid ? secondItem : null
  ].filter(item => item)

  return [filters]
}

const toModelQuery = raw => {
  const filters = raw.where.length && !Array.isArray(raw.where[0])
    ? toModelFilters(raw)
    : raw.where

  return {
    guid: raw.guid,
    userId: raw.userId,
    projectId: raw.projectId,
    fileType: raw.fileType,
    queryType: raw.queryType,
    name: raw.name,
    description: raw.description,
    data: {
      orderBy: raw.orderBy || null,
      selectFields: raw.selectSet,
      orderByConditions: raw.orderByConditions || [],
      filters
    }
  }
}

const toRawQuery = model => {
  return {
    guid: model.guid,
    userId: model.userId,
    projectId: model.projectId,
    fileType: model.fileType,
    queryType: model.queryType,
    name: model.name,
    description: model.description,
    selectSet: model.data.selectFields,
    where: model.data.filters,
    orderBy: model.data.orderBy,
    orderByConditions: model.data.orderByConditions
  }
}

const formatQueries = raw => {
  return raw
    .sort((a, b) => (a.queryType > b.queryType) ? 1 : -1)
    .map(query => {
      switch (query.queryType) {
        case QUERY_TYPE.QUERY:
          return toModelQuery(query)

        default:
          return query
      }
    })
}

const unformatQuery = model => {
  switch (model.queryType) {
    case QUERY_TYPE.QUERY:
      return toRawQuery(model)

    default:
      return model
  }
}

// sets the API region

const setAPIRegion = region => {
  __region = region
}

// #/source/{:operation|:id}

const getSourceDataOnly = async guid => {
  const sourceData = await client.get(`/source/${guid}`, { region: __region })

  return sourceData.map(object => ({
    ...object,
    values: object.values?.map(w => ({
      value: null, ...w
    }))
  }))
}

const getSourceData = async projectId => {
  const sourceData = await client.get(`/source/${projectId}`, { region: __region })

  const guids = sourceData.map(data => data.guid)

  const fileData = guids.map(async (guid) => {
    const response = await client.get(`/source/stats/${projectId}?guid=${guid}`, { region: __region })
    return response
  })

  const files = await Promise.all(fileData)

  // dynamo doesnt return null values, so it will be undefined. To be able to corretly map a null value we have to have null ( null ! == undefined).
  const statValues = files
    .flat(1)
    .map(object => ({
      ...object,
      values: object.values.map(w => ({ value: null, ...w }))
    }))

  return [
    sourceData,
    statValues
  ]
}

const insertIntoSourceData = (projectId, tableName, insertConditions) => {
  return client.post('/source/insert', { projectId, tableName, insertConditions }, { region: __region })
}

const updateSourceData = (
  projectId,
  tableName,
  updateSetConditions,
  updateWhereConditions,
  deleteWhereConditions,
  isProject,
  isDelete
) => {
  return client.post('/source/update', { projectId, tableName, updateSetConditions, updateWhereConditions, deleteWhereConditions, isProject, isDelete }, { region: __region })
}

const deleteSource = (
  id,
  guid,
  deleteS3Data = false,
  isReference = false
) => {
  return client.post('/source/delete', { id, guid, deleteS3Data, isReference }, { region: __region })
}

const querySourceData = async (
  projectId,
  tableName,
  querySelectFields,
  queryWhereConditions,
  paging,
  isPrecalculation,
  orderByConditions = []
) => {
  const MAX_TRIES = 2

  for (let i = 0; i < MAX_TRIES; ++i) {
    try {
      return await client.post('/source/query', { projectId, tableName, querySelectFields, queryWhereConditions, paging, isPrecalculation, orderByConditions }, { region: __region })
    } catch (err) {
      console.error(err)

      await delay(40000)
    }
  }

  throw new Error('Failed after max attempts reached')
}

const querySourceValidation = (
  projectId,
  tableName,
  querySelectFields,
  isPrecalculation,
  isRedShift
) => {
  const body = {
    projectId,
    tableName,
    querySelectFields,
    isPrecalculation,
    isRedShift
  }
  return client.post('/source/validate', body, { region: __region })
}

// #/source/savedworkflows/*

const saveWorkflow = (userId, body) => {
  return client.put(`/source/savedworkflows/saved?userId=${userId}`, body)
}

const deleteWorkflow = (userId, guid) => {
  return client.delete(`/source/savedworkflows/saved?userId=${userId}`, { guid })
}

/*
  we are grabbing all savedWorkflows from a global table in dynamo
  and the region is always us-east-1 in the baseUrl no matter what region you are in
*/
const getSavedWorkflows = (userId, body) => {
  return client.get(`/source/savedworkflows/saved?userId=${userId}`, { body })
}

const executeWorkflow = body => {
  return client.post('/source/workflow', body, { region: __region })
}

// #/source/savedqueries/*

const saveQuery = async (userId, query) => {
  const body = unformatQuery(query)
  const savedQuery = await client.put(`/source/savedqueries/saved?userId=${userId}`, body, { region: __region })

  return formatQueries(savedQuery)
}

const saveQueryNewCol = async (userId, body) => {
  return client.put(`/source/savedqueries/saved?userId=${userId}`, body, { region: __region })
}

const deleteQuery = (userId, guid) => {
  return client.delete(`/source/savedqueries/saved?userId=${userId}`, { guid }, { parseAsText: true })
}

const getSavedQueries = async userId => {
  const savedQueries = await client.get(`/source/savedqueries/saved?userId=${userId}`, { region: __region })

  return formatQueries(savedQueries)
}

const saveQueryAggregation = (userId, body) => {
  return client.put(`/source/savedqueries/saved?userId=${userId}`, body, { region: __region })
}

const saveQueryMerge = (userId, body) => {
  return client.put(`/source/savedqueries/saved?userId=${userId}`, body, { region: __region })
}

// #/source/* Data Prep actions

const previewMerge = body => {
  return client.put('/source/merge/merge', body, { region: __region })
}

const startMerge = body => {
  return client.post('/source/merge/merge', body, { region: __region })
}

const startNewColumn = body => {
  return client.post('/source/newcolumn', body, { region: __region })
}

const startAggregation = body => {
  return client.post('/source/aggregation', body, { region: __region })
}

const searchLogs = (guid, type, body) => {
  return client.get(`/source/logsearch?fileGuid=${guid}&eventType=${type}`, body, { region: __region })
}

const startConvert = body => {
  return client.post('/source/convert', body, { region: __region })
}

const startCommonFormat = body => {
  return client.post('/source/createcff', body, { region: __region })
}

// #/source/savedmappings/saved/*

const saveMappings = (
  userId,
  projectId,
  guid,
  name,
  mappings,
  studyType
) => {
  return client.put(`/source/savedmappings/saved?userId=${userId}`, { guid, projectId, studyType, name, mappings }, { region: __region })
}

const deleteMappings = (userId, guid) => {
  return client.delete(`/source/savedmappings/saved?userId=${userId}`, { guid }, { parseAsText: true })
}

const getSavedMappings = userId => {
  return client.get(`/source/savedmappings/saved?userId=${userId}`, { region: __region })
}

// #/precalc/check

/*
  NOTES:
  - These API clients should probably go into another file since they aren't part of /source/* route
  - These functions are wrappers around the exact same endpoint
    - Might make sense to separate the endpoint into separate endpoints (backend)
    - Make a single, lean API client function, and move any helper code to its specific domain
 */

const startPreCalCheck = (
  formatFiles,
  projectId,
  type,
  nonCriticalChecks,
  skipMapCheck = [],
  calcIBNR
) => {
  const checks = getPreCalCheck(type, nonCriticalChecks, skipMapCheck, formatFiles, calcIBNR)

  const body = {
    id: projectId.toString(),
    type,
    checks,
    formatFiles
  }

  return client.post('/precalc/check', body, { region: __region })
}

const startPostCalCheck = (projectId, type, modelInput, jobId) => {
  const CHECKS = [
    {
      type: 'LAPSE',
      fileType: 'I'
    },
    {
      type: 'CLAIMS',
      fileType: 'I'
    },
    {
      type: 'EXP_VS_RISKH',
      fileType: 'I'
    },
    {
      type: 'POLICY_TRACKER',
      fileType: 'I'
    }
  ]

  return client.post('/precalc/check', { id: projectId.toString(), jobId, type, modelInput, formatFiles: [], checks: CHECKS }, { region: __region })
}

export default {
  getSourceDataOnly,
  getSourceData,
  querySourceData,
  querySourceValidation,
  updateSourceData,
  saveQuery,
  saveWorkflow,
  executeWorkflow,
  deleteWorkflow,
  deleteQuery,
  getSavedQueries,
  getSavedWorkflows,
  deleteSource,
  insertIntoSourceData,
  previewMerge,
  startMerge,
  startNewColumn,
  startAggregation,
  searchLogs,
  setAPIRegion,
  startConvert,
  startCommonFormat,
  startPreCalCheck,
  saveMappings,
  deleteMappings,
  getSavedMappings,
  saveQueryNewCol,
  saveQueryAggregation,
  saveQueryMerge,
  startPostCalCheck,
  getRediChecks
}
