import referenceApi from '../objects/referenceApi'
import { updatedAction } from './common/update'

const INITIAL_STATE = {
  isLoaded: false,
  rgaColumns: [],
  products: [],
  countries: [],
  yesNo: [],
  studyTypes: [],
  policyRecordStructure: [],
  dataFrequencyType: [],
  basis: []
}

export const loadedReferenceDataAction = {
  type: 'refData/LOADED_REFERENCE_DATA'
}
export const loadedBasisAction = {
  type: 'refData/LOADED_BASIS'
}
export const loadBasis = async (dispatch) => {
  const basis = await referenceApi.getBasis()

  return dispatch({ ...loadedBasisAction, payload: { basis } })
}
export const loadReferenceData = async (dispatch) => {
  const data = await referenceApi.getReferenceData()

  return dispatch({ ...loadedReferenceDataAction, payload: data })
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case loadedReferenceDataAction.type: {
      const rgaColumns = payload[1]
      const {
        products,
        countries,
        dataFrequencyType,
        policyRecordStructure,
        studyTypes,
        yesNo
      } = payload[0]

      return {
        ...state,
        isLoaded: true,
        rgaColumns,
        products,
        countries,
        dataFrequencyType,
        policyRecordStructure,
        studyTypes,
        yesNo
      }
    }
    case loadedBasisAction.type:
      return {
        ...state,
        ...payload
      }
    case updatedAction.type:
      return {
        ...state,
        ...payload.refData
      }

    default:
      return state
  }
}
