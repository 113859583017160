import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { useSelector } from 'react-redux'
import { getTestIdProp } from '../../commonFunc'

const DUMMY_COLUMN_DEFS = [{
  field: null,
  suppressHeaderMenuButton: true
}]

const AgGrid = (props) => {
  const testIdProp = getTestIdProp(props);
  const { onGridReady, gridRef, execute } = props;
  const drawerOpen = useSelector(store => store.global.drawerOpen)
  const containerStyle = useMemo(
    () => ({
      height: '-webkit-fill-available',
      width: drawerOpen ? '-webkit-fill-available' : 'calc(99vw)',
      position: 'relative',
      overflowY: 'none',
      overflowX: 'none'
    }),
    [drawerOpen]
  )
  const gridStyle = useMemo(
    () => ({
      overflowY: 'scroll',
      overflowX: 'auto',
      height: '550px'
    }),
    []
  )

  const getProps = () => {
    return {
      pagination: true,
      paginationPageSize: execute.preCalc ? execute.pageSize : 25,
      cacheBlockSize: execute.preCalc ? execute.pageSize : 25
    }
  }
  return (
    <div style={containerStyle} {...testIdProp}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          {...getProps()}
          defaultColDef={{
            headerValueGetter: params => {
              return (
                (params?.colDef?.field?.toUpperCase()) || null
              )
            }
          }}
          suppressFieldDotNotation={true}
          ref={gridRef}
          blockLoadDebounceMillis={500}
          rowModelType={'serverSide'}
          rowSelection={'single'}
          onGridReady={onGridReady}
          suppressExcelExport={true}
          paginationPageSizeSelector={false}
          columnDefs={DUMMY_COLUMN_DEFS}
        ></AgGridReact>
      </div>
    </div>
  )
}
AgGrid.propTypes = {
  onGridReady: PropTypes.any,
  gridRef: PropTypes.any,
  type: PropTypes.any,
  execute: PropTypes.any
}

export default AgGrid
