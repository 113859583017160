import loadingJobStep from '../objects/data/loadingJobStep'
import preCalApi from '../objects/preCalApi'
import projectApi from '../objects/projectApi'
import sourcesApi from '../objects/sourcesAPI'
import { deepFreeze } from '../utils'
import stepFunctionStatusLoop from './common/stepFunctionStatusLoop'
import { USER_MESSAGES } from '../../stringConstants'

const { messageMap } = loadingJobStep

export const initialState = deepFreeze(/** @type {ValueMappingState} */ ({
  isCreating: false,
  isFinished: false,
  message: ''
}))

export const finishedProcessAction = {
  type: 'valueMapping/FINISHED_PROCESS'
}

export const creatingCFAction = {
  type: 'valueMapping/CREATING_COMMON_FORMAT'
}

export const createdCFAction = {
  type: 'valueMapping/CREATED_COMMON_FORMAT'
}

export const startedREDEPrecalcChecksAction = {
  type: 'valueMapping/STARTED_REDE_PRECALC_CHECKS'
}

export const completedREDEPrecalcChecksAction = {
  type: 'valueMapping/COMPLETED_REDE_PRECALC_CHECKS'
}

export const startedCCFTAction = {
  type: 'valueMapping/STARTED_CCFT'
}

export const completedCCFTAction = {
  type: 'valueMapping/COMPLETED_CCFT'
}

export const setRedirectAction = {
  type: 'query/SET_REDIRECT'
}

export const createCommonFormat = async (dispatch, getState) => {
  const {
    mapping: { mappings },
    project,
    project: { files, id: projectId, studyType },
    user: { sid: userId, ownerId: currentUser }
  } = structuredClone(getState())
  const hasSucceededFile = (ourFiles, mapping) => {
    const file = ourFiles.find(y => y.guid === mapping.guid)
    return file && file.profileStatus === 'SUCCEEDED'
  }
  const mergedMappings = mappings
    .filter(mapping => mapping.include && hasSucceededFile(files, mapping))
    .map(mapping => {
      const file = files.find(y => y.guid === mapping.guid)
      return { ...mapping, fileName: file.fileName, status: 'SUCCEEDED' }
    })
  const valueToSubmit = {
    projectId,
    mappings: mergedMappings,
    userId,
    currentUser
  }
  dispatch({
    ...creatingCFAction,
    payload: {
      isCreating: true,
      ...messageMap[studyType][0],
      errors: [],
      message: ''
    }
  })
  // eslint-disable-next-line no-return-assign
  files.forEach(x => x.isDirty = false)
  try {
    await projectApi.putProject({
      ...project,
      files
    })

    const { executionArn } = await sourcesApi.startCommonFormat(valueToSubmit)
    dispatch({
      ...creatingCFAction,
      payload: {
        isCreating: true,
        ...messageMap[studyType][1],
        errors: [],
        message: ''
      }
    })
    const moveOnAfterCommonFormat = async arn => {
      // check errors in SUCCEEDED status
      const errorsOnSucceed = arn?.output && ('Error' in JSON.parse(arn.output)) ? JSON.parse(arn.output).Error : false

      if (arn.status === 'SUCCEEDED' && !errorsOnSucceed) {
        const project = await projectApi.getProject(projectId)
        const errors = project.formatFiles
          .filter(x => x.status === 'error')
          .map(x => x.errors.map(x => x.result)).flat()
        const currentFormatFiles = errors.length === 0 ? project.formatFiles : []
        const messageObject = errors.length > 0
          ? messageMap[studyType][4]
          : messageMap[studyType][2]
        if (messageObject.code === 3) {
          dispatch(redePrecalChecks(currentFormatFiles, project.calcIBNR))
        }
        return dispatch({
          ...createdCFAction,
          payload: {
            navigation: {
              files
            },
            project: {
              formatFiles: currentFormatFiles,
              files
            },
            valueMapping: {
              isFinished: true,
              ...messageObject,
              errors,
              redirect: messageObject.code === 3
            }
          }
        })
      } else {
        return dispatch({
          ...createdCFAction,
          payload: {
            valueMapping: {
              isFinished: true,
              ...messageMap[studyType][3],
              errors: [errorsOnSucceed || arn.output]
            }
          }
        })
      }
    }

    return stepFunctionStatusLoop(
      executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      moveOnAfterCommonFormat
    )
  } catch (ex) {
    return dispatch({
      ...createdCFAction,
      payload: {
        valueMapping: {
          isFinished: false,
          isCreating: false,
          ...messageMap[studyType][3]
        }
      }
    })
  }
}

export const redePrecalChecks = (formatFiles, calcIBNR = false) => async (dispatch, getState) => {
  const {
    project: { id, studyType }
  } = getState()
  const { executionArn } = await sourcesApi.startPreCalCheck(formatFiles, id, studyType, null, null, calcIBNR)
  dispatch({
    ...startedREDEPrecalcChecksAction,
    payload: {
      ...messageMap[studyType][5]
    }
  })

  const moveOnAfterPreCalcCheck = async arn => {
    if (arn.status === 'SUCCEEDED') {
      const output = arn.output.replace('[', '').replace(']', '')
      const modifiedOutput = output.split(',')
      const isValidated = modifiedOutput.every(num => num === '0')

      if (isValidated) {
        dispatch(rediCCFT)
      }

      return dispatch({
        ...completedREDEPrecalcChecksAction,
        payload: {
          preCalc: {
            load: false
          },
          valueMapping: {
            isFinished: true,
            ...messageMap[studyType][isValidated ? 8 : 6],
            errors: isValidated ? [] : [' ']
          }
        }
      })
    } else {
      return dispatch({
        ...completedREDEPrecalcChecksAction,
        payload: {
          valueMapping: {
            isFinished: true,
            ...messageMap[studyType][12],
            errors: [' ']
          }
        }
      })
    }
  }

  return stepFunctionStatusLoop(
    executionArn,
    ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
    moveOnAfterPreCalcCheck
  )
}

export const rediCCFT = async (dispatch, getState) => {
  const {
    project: { formatFiles, id, studyType }
  } = getState()

  const { executionArn } = await preCalApi.runRediCCFT({ formatFiles, projectId: id })
  dispatch({
    ...startedCCFTAction,
    payload: {
      ...messageMap[studyType][8]
    }
  })

  const moveOnAfterCCFT = async arn => {
    if (arn.status === 'SUCCEEDED' && arn.output && arn.output.includes('formatFiles')) {
      const output = JSON.parse(arn.output)
      return dispatch({
        ...completedCCFTAction,
        payload: {
          preCalc: {
            fixMessage: 'Successfully completed CCFT and Fixes',
            fixingFlag: false
          },
          project: {
            formatFiles: output.formatFiles
          },
          valueMapping: {
            isFinished: true,
            ...messageMap[studyType][11],
            errors: []
          }
        }
      })
    } else {
      return dispatch({
        ...completedCCFTAction,
        payload: {
          preCalc: {
            fixMessage: USER_MESSAGES.rediCCFTStepFuncionFailed,
            fixingFlag: false
          },
          valueMapping: {
            isFinished: true,
            ...messageMap[studyType][10],
            errors: [arn.output]
          }
        }
      })
    }
  }

  return stepFunctionStatusLoop(
    executionArn,
    ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
    moveOnAfterCCFT
  )
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case creatingCFAction.type:
    case startedREDEPrecalcChecksAction.type:
    case startedCCFTAction.type:
    case setRedirectAction.type:
      return {
        ...state,
        ...payload
      }
    case createdCFAction.type:
    case completedREDEPrecalcChecksAction.type:
    case completedCCFTAction.type:
      return {
        ...state,
        ...payload.valueMapping
      }
    case finishedProcessAction.type:
      return {
        ...state,
        isCreating: false,
        isFinished: false,
        message: ''
      }
    default:
      return state
  }
}
/**
 * @typedef {object} ValueMappingState
 * @property {boolean} isCreating
 * @property {boolean} isFinished
 * @property {string} message
 */
