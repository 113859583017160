import React from 'react'
import ReactSelect from 'react-select'
import SearchBox from '../../../components/form/SearchBox'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'

const OPTIONS_FILE_REDI = [
  {
    label: 'Inforce',
    value: 'I'
  },
  {
    label: 'Termination',
    value: 'T'
  },
  {
    label: 'Inforce+Termination',
    value: 'IT'
  }
]

const Select = styled(ReactSelect)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

export default function ViewEdpTables ({ studyType, handleAddEdpTable, setViewsEdp, edpViews }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [filterQueries, setFilterQueries] = React.useState(undefined)
  const OPTIONS_FILE_SKIP_MAP = [
    {
      label: 'val policy',
      value: 'I'
    },
    {
      label: 'val terminated',
      value: 'T'
    },
    {
      label: 'evexp risk history',
      value: 'RH'
    },
    {
      label: 'treaty',
      value: 'TY'
    }
  ]

  const getFileTypeOptions = (studyType) => {
    switch (studyType) {
      case 'redi':
        return OPTIONS_FILE_REDI
      case 'skipMap':
        return OPTIONS_FILE_SKIP_MAP

      default:
        throw new Error('Invalid studyType')
    }
  }

  const allowSpecialCharacters = () => {
    try {
      return edpViews.filter(view =>
        view.fileName.toLowerCase()
          .match(filterQueries.toLowerCase()))
    } catch (e) {
      return edpViews.filter(view =>
        view.fileName.toLowerCase()
          .match(''.toLowerCase()))
    }
  }
  const rowsFilter = filterQueries === undefined
    ? edpViews
    : allowSpecialCharacters()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const fileTypeOptions = getFileTypeOptions(studyType)
  return edpViews && rowsFilter && (
        <Grid item xs={12}>
            <Grid item xs={12}>
                <Typography variant="h6">
                Views
                </Typography>
                <SearchBox
                  data-testid='search-edp-view'
                  onChange={event => setFilterQueries(event.target.value)}
                />
                <Button
                  data-testid='add-select-edp-view'
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    edpViews.filter(edpView => edpView.type)
                      .map((view) => {
                        return handleAddEdpTable(view)
                      })
                  }}
                >
                  Add {edpViews.filter(edpView => edpView.type).length}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Typography variant='h6'>
                        Select Table
                    </Typography>
                    <Table aria-labelledby="tableTitle" size='small'>
                        <TableHead>
                            <TableRow style={{ tableLayout: 'fixed' }}>
                                <TableCell width="70%" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>View</TableCell>
                                <TableCell width="30%" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map(objectView => (
                                    <TableRow style={{ tableLayout: 'fixed' }} data-testid={`views-edp-${objectView.fileName}`} key={objectView.fileName}>
                                        <TableCell width="70%" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{objectView.fileName}</TableCell>
                                        <TableCell width="30%" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                            <Select
                                                data-testid={`select-type-${objectView.fileName}`}
                                                placeholder=""
                                                styles={SingleValueStyles}
                                                isClearable
                                                key={`select-type-${objectView.fileName}`}
                                                menuPosition={'fixed'}
                                                options={fileTypeOptions}
                                                components={{ SingleValue }}
                                                value={
                                                    fileTypeOptions.find((option) => {
                                                      return option.value === objectView.type
                                                    }) || null
                                                }
                                                onChange={(e) => {
                                                  const updatedArray = [...edpViews].map(view => {
                                                    if (view.fileName === objectView.fileName) {
                                                      view.type = e?.value
                                                    }
                                                    return view
                                                  })
                                                  setViewsEdp(updatedArray)
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                              ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid='edp-tablePagination'
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={edpViews.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>)
}

ViewEdpTables.propTypes = {
  studyType: PropTypes.string,
  handleAddEdpTable: PropTypes.func,
  setViewsEdp: PropTypes.func,
  edpViews: PropTypes.array
}
