import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import Badge from '@mui/material/Badge'
import ErrorIcon from '@mui/icons-material/Error'
import { selectItem } from '../../store/pages/mapping'
import { styled } from '@mui/material'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.background.paper,
    color: 'black'
  }
}))

const message = 'There is a limit of correcting 100 distinct Source Column values. The current Source Column has over 100 distinct incorrect values. Please map a different Source Column or use the Data Prep page to reduce the number of distinct values and/or change the column type for the Source Column.'
const generateMappingList = (
  fileType,
  guid,
  files,
  handleSourceFocusChange,
  handleTargetChange,
  fileMappings,
  searchFilter,
  profileData,
  filterByError,
  dispatch) => {
  const sourceColumnOptions = files.find(file => file.guid === guid)
    ? files.find(file => file.guid === guid)
      .columns.map(({ Name, Type: sourceDataType }, colIdx, selected = false) => ({
        value: colIdx.toString(),
        label: Name,
        sourceDataType,
        selected
      }))
    : []

  sourceColumnOptions.splice(0, 0, { value: 'Unmapped', label: 'Unmapped' })
  const getIcon = (column) => {
    const validateDate = column.targetDataType === 'date' ? column.targetDataType !== column.sourceDataType : false
    return !validateDate
      ? (<Tooltip title='Data type mismatch' aria-label='Data type mismatch'>
        <WarningIcon/>
      </Tooltip>)
      : (<Tooltip title='Date must match the source type'>
        <ErrorIcon/>
      </Tooltip>)
  }
  const fileColumns = searchFilter
    ? fileMappings.columns.filter(x => x.target.toLowerCase().includes(searchFilter.toLowerCase()))
    : fileMappings.columns

  const getErrorMessage = (profileData, column) => {
    const currentSource = profileData.find(i => i.fieldName === column.source)
    return currentSource && currentSource.distinctCnt > 100
      ? (<Tooltip title= {message} aria-label={column.toolTip}>
      <ErrorIcon color="secondary"/>
    </Tooltip>)
      : ''
  }

  const mappedItems = fileColumns && fileColumns.map((column, idx) => {
    const hasError = column.erros > 0
    const errorClassName = hasError ? 'error' : ''
    const displayRow = (hasError && filterByError) || !filterByError

    return displayRow &&
      <TableRow id={idx}
        key={idx}
        className={errorClassName}
        onClick={async () => {
          dispatch(selectItem(fileType, guid, idx, column.targetColumnName, column.target, column.source))
          handleSourceFocusChange(column.source, column.isMultiple)
          handleTargetChange(column.target)
        }}>
        <TableCell className={errorClassName}>
          {
            column.dataTypeMismatch
              ? getIcon(column)
              : ''
          }
          { hasError && getErrorMessage(profileData, column)}
        </TableCell>
        <TableCell className={errorClassName}>
          {column.toolTip &&
            <Tooltip title={column.toolTip} aria-label={column.toolTip}>
              <InfoIcon />
            </Tooltip>
          }
          {`${column.target} (${column.targetDataType})`}
        </TableCell>
        <TableCell className={errorClassName}>
          <StyledBadge
            color="secondary"
            max={9999}
            howZero={false}
            badgeContent={column.erros}
          >
            {`${column.source || 'THIS COLUMN IS NOT MAPPED'}`}
          </StyledBadge>

        </TableCell>
      </TableRow>
  })
  if (!mappedItems.length) {
    return (
        <TableRow>
          <TableCell colSpan={3} align="center" sx={{ textAlign: 'center', height: '200px' }}>
            No results found
          </TableCell>
        </TableRow>
    )
  }
  return mappedItems
}

export { generateMappingList }
