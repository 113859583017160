import { createTheme } from '@mui/material/styles';
export default createTheme({
  spacing: 4,
  palette: {
    background: {
      default: 'white'
    },
    primary: {
      main: '#c62828'
    },
    secondary: {
      main: '#ffffff'
    }
  },
  components: {
    MuiTableBody: {
      styleOverrides: {
        root: {
          '&>tr': {
            background: 'white',
            '&:nth-of-type(odd)': {
              background: '#F5F5F5'
            },
            '&.error': {
              background: '#c62828'
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.error': {
            color: 'white'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#ffffff'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 'em'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          backgroundColor: 'white'
        },
        containedSecondary: {
          color: 'white',
          backgroundColor: '#c62728'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: '#ffffff'
        },
        root: {
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#ffffff',
            color: '#c62828'
          },
          '&.Mui-selected': {
            color: 'white'
          },
          '&.Mui-selected:hover': {
            color: '#c62828'
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // Small screens (tablets, landscape phones)
      md: 900, // Medium screens (small laptops)
      lg: 1200, // Large screens (desktops)
      xl: 1536 // Extra large screens
    }
  }
})
