import { client } from './apiHelper'
import countries from './data/countries.json'
import dataFrequencyType from './data/dataFrequency.json'
import policyRecordStructure from './data/policyRecordStructure.json'
import products from './data/products.json'
import rgaColumns from './data/rga_columns.json'
import studyTypes from './data/studyTypes.json'
import yesNo from './data/yesNo.json'

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const firstIndexReferenceData = {
  products,
  countries,
  dataFrequencyType,
  policyRecordStructure,
  studyTypes,
  yesNo
}

export const referenceData = {
  ...firstIndexReferenceData,
  rgaColumns
}

/* reference table APIs */

const searchReferenceTable = (opts = {}) => {
  const ownerFilter = opts.ownerId ? `ownerId=${opts.ownerId}` : ''
  const regionFilter = opts.region ? `region=${opts.region.replace('-1', '')}` : ''

  const filters = [
    'a=1', /* temporary params to there's always a querystring param in the endpoint. This guarantees that the querystringParameters property is created on the event object in the lambda. */
    ownerFilter,
    regionFilter
  ].filter(item => item).join('&')

  const uri = [
    '/reference/search',
    filters
  ].filter(item => item).join('?')

  return client.get(uri, { region: __region })
}

const getReferenceTable = (id) => {
  return client.get(`/reference/crud/reference?id=${id}`, { region: __region })
}

const createReferenceTable = (body) => {
  return client.post('/reference/crud/reference?id=1', body, { region: __region })
}

const updateReferenceTable = (id, body) => {
  return client.put(`/reference/crud/reference?id=${id}`, body, { region: __region })
}

const deleteReferenceTable = (id) => {
  return client.delete(`/reference/crud/reference?id=${id}`, {}, { parseAsText: true })
}

/* app-level reference data (no related to ref tables) */

const getReferenceData = () => {
  const {
    REACT_APP_RGAENV: rgaEnv
  } = process.env

  const isLowerEnv = ['poc'].includes(rgaEnv)

  const filteredCountries = isLowerEnv
    ? firstIndexReferenceData.countries.filter(country => country.region !== 'ap-northeast-1')
    : countries;

  return Promise.all([
    Promise.resolve({ ...firstIndexReferenceData, countries: filteredCountries }),
    Promise.resolve(rgaColumns)
  ])
}

const getBasis = async () => {
  const res = process.env.REACT_APP_RGAENV !== 'poc'
    ? await client.get('/source/basis', { region: __region })
    : []

  const items = res.rows || []

  /*
    NOTES:
    - Incoming 'case#' field is a numeric string-type, but has a floating-point value
    - Example: a case# field would come in as '384.000000000000000000' instead of just '384'
    - The mapping operation below fixes this by coercing to a Number-type, then back to string
    - We coerce back to a string because this field has no arithmetic significance
   */

  return items.map(item => ({
    caseNumber: `${parseInt(item['case#'])}`, // gets rid of floating-point zeroes
    name: item.name,
    description: item.description
  }))
}

export default {
  setAPIRegion,
  searchReferenceTable,
  getReferenceTable,
  createReferenceTable,
  updateReferenceTable,
  deleteReferenceTable,
  getReferenceData,
  getBasis
}
