import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import mappingApi from '../../store/objects/mappingApi'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux'
import { changeRegion } from '../../store/pages/global'
import { changedAction } from '../../store/pages/project/actions'
import { getAccess } from '../../store/pages/project/async'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  SingleValue
} from '../../components/sql/SelectSingleValue'

import {
  piiTooltip,
  dataFrequencyTooltip,
  policyStructureTooltip
} from './inputQuestionsToolTips'

const styles = {
  textHead: {
    fontSize: '1rem'
  },
  icon: {
    width: '.7rem'
  },
  textField: {
    backgroundColor: 'white',
    width: '93%'
  },
  eachItem: {
    padding: '0'
  },
  dateShade: {
    backgroundColor: 'white'
  },
  selected: {
    control: (base) => ({
      ...base,
      height: '45px',
      width: '93%'
    })
  },
  input1: {
    height: '12px',
    width: '100%'
  },
  disp: {
    justifyContent: 'start',
    paddingLeft: '2px'
  },
  linrBreak: {
    marginTop: '40px'
  },
  margin: {
    margin: '6px'
  },
  paddingTop: {
    paddingTop: '7px'
  },
  iconColor: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  unImpItem: {
    paddingLeft: '0.7rem'
  }
}

function InputQuestions (props) {
  const dispatch = useDispatch()
  const project = useSelector(store => store.project)
  const refData = useSelector(store => store.refData)
  const user = useSelector(store => store.user)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId, 'UPLOAD'
  )

  const sharedWithIds = project.sharedWithIds
    .map(item => item.name + '(' + item.role + ') ')
    .join(',')

  const styleObj = {
    control: base => ({
      ...base,
      height: '45px !important',
      backgroundColor: '#a9a9a9',
      width: '93%'
    })
  }

  const handleTextfieldChange = React.useCallback(event => {
    dispatch({
      ...changedAction,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  }, [dispatch])

  const handleSelectChange = React.useCallback((option, event) => {
    switch (event.name) {
      case 'studyType':
        mappingApi.removeMappings(project.id)
        dispatch({
          ...changedAction,
          payload: {
            key: event.name,
            value: option.value
          }
        })
        break

      case 'country':
        changeRegion(option.value)
        dispatch({
          ...changedAction,
          payload: {
            key: event.name,
            value: option.value
          }
        })
        break
      default:
        dispatch({
          ...changedAction,
          payload: {
            key: event.name,
            value: option.value
          }
        })
    }
  }, [
    project.id,
    dispatch
  ])

  const handleDateChange = React.useCallback(value => {
    dispatch({
      ...changedAction,
      payload: {
        key: 'projectDate',
        value: value.toISOString()
      }
    })
  }, [dispatch])

  const handleIconButtonClick = React.useCallback(() => {
    props.onOpen()
  }, [props])

  return (
    <Grid container sx={styles.margin}>
      <Grid item xs={9} sx={{ padding: '6px' }}>
        <Grid container direction="row">
          <Grid item xs={6}>
            <Grid container direction="column">
              <Grid container direction="row" sx={styles.eachItem}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={styles.textHead}>
                    <Icon
                      color="primary"
                      sx={styles.icon}
                    >*</Icon>

                    <span>Project Name:</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    data-testid='textField-projectName'
                    name='projectName'
                    variant="outlined"
                    disabled={!access}
                    sx={styles.textField}
                    inputProps={{ style: styles.input1 }}
                    value={project.projectName}
                    onChange={handleTextfieldChange}
                  />
                </Grid>
              </Grid>

              <Grid sx={styles.linrBreak}></Grid>

              <Grid container direction="row" sx={styles.eachItem}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={styles.textHead}>
                    <Icon
                      color="primary"
                      sx={styles.icon}
                    >*</Icon>

                    <span>Company Name:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    data-testid='textField-companyName'
                    name='clientName'
                    variant="outlined"
                    disabled={!access}
                    sx={styles.textField}
                    inputProps={{ style: styles.input1 }}
                    value={project.clientName}
                    onChange={handleTextfieldChange}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={styles.eachItem} data-testid='clientProduct'>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={styles.textHead}>
                    <Icon
                      color="primary"
                      sx={styles.icon}
                    >*</Icon>

                    <span>Client Product:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Select
                    data-testid='select-clientProduct'
                    name='product'
                    isDisabled={!access}
                    styles={styles.selected}
                    components={{ SingleValue }}
                    options={refData.products}
                    value={project.product && refData.products.find(
                      option => option.value === project.product
                    )}
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={styles.eachItem} data-testid='clientCountry'>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={styles.textHead}>
                    <Icon
                      color="primary"
                      sx={styles.icon}
                    >*</Icon>

                    <span>Client Country:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Select
                    data-testid='select-country'
                    name='country'
                    styles={styles.selected}
                    isDisabled={!!project.id || !access}
                    components={{ SingleValue }}
                    options={refData.countries.filter(x => x.region)}
                    value={project.country && refData.countries.find(
                      option => option.value === project.country
                    )}
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid sx={styles.linrBreak}></Grid>

              <Grid container direction="row" sx={styles.eachItem} data-testid='testData'>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={styles.textHead}>
                    <Icon
                      color="primary"
                      sx={styles.icon}
                    >*</Icon>

                    <span>Is Test Data</span>

                    <Tooltip title="Test projects are any project where you are testing out REDi. Your answer affects the length of time your data is retained.">
                      <InfoIcon sx={styles.iconColor} />
                    </Tooltip>

                    <span>:</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    data-testid='select-isTestData'
                    name='isTestData'
                    isDisabled={!access}
                    styles={styles.selected}
                    components={{ SingleValue }}
                    options={refData.yesNo}
                    value={project.isTestData !== '' && refData.yesNo.find(
                      option => option.value === project.isTestData.toString()
                    )}
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={styles.eachItem} data-testid='pII'>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={styles.textHead}
                  >
                    <Icon
                      color="primary"
                      sx={styles.icon}
                    >*</Icon>

                    Personally Identifiable Info (PII)

                    <Tooltip title={piiTooltip}>
                      <InfoIcon sx={styles.iconColor} />
                    </Tooltip>

                    <span>:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Select
                    data-testid='select-PII'
                    name='isPII'
                    isDisabled={!access}
                    styles={styles.selected}
                    components={{ SingleValue }}
                    options={refData.yesNo}
                    value={project.isPII !== '' && refData.yesNo.find(
                      option => option.value === project.isPII.toString()
                    )}
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction="column">
              <Grid container direction="row" sx={styles.eachItem} data-testid='studyType'>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={styles.textHead}>
                    <Icon
                      color="primary"
                      sx={styles.icon}
                    >*</Icon>

                    <span>Study Type:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Select
                    data-testid='select-studyType'
                    name='studyType'
                    isDisabled={!access || project.id !== undefined || project.files.length}
                    styles={styles.selected}
                    components={{ SingleValue }}
                    options={refData.studyTypes}
                    value={project.studyType && refData.studyTypes.find(
                      option => option.value === project.studyType
                    )}
                    onChange={props.handleSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid sx={styles.linrBreak}></Grid>

              <Grid container direction="row" sx={styles.eachItem} data-testid='dataFrequencyType'>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={[styles.textHead, styles.unImpItem]}
                  >
                    <span>Data Frequency Type</span>

                    <Tooltip title={dataFrequencyTooltip}>
                      <InfoIcon sx={styles.iconColor} />
                    </Tooltip>

                    <span>:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Select
                    data-testid='select-dataFrequencyType'
                    name='dataFrequencyType'
                    styles={project.studyType === 'redi' ? styles.selected : styleObj}
                    components={{ SingleValue }}
                    options={refData.dataFrequencyType}
                    isDisabled={!access || project.studyType !== 'redi'}
                    value={project.studyType === 'redi'
                      ? project.dataFrequencyType
                        ? refData.dataFrequencyType.find(
                          option => option.value === project.dataFrequencyType
                        )
                        : ''
                      : ''
                    }
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={styles.eachItem} data-testid='policyRecordStructures'>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={[styles.textHead, styles.unImpItem]}
                  >
                    Policy Record Structures

                    <Tooltip title={policyStructureTooltip}>
                      <InfoIcon sx={styles.iconColor} />
                    </Tooltip>

                    <span>:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Select
                    data-testid='select-policyRecordStructure'
                    name='policyRecordStructure'
                    styles={project.studyType === 'redi' ? styles.selected : styleObj}
                    isDisabled={!access || project.studyType !== 'redi'}
                    components={{ SingleValue }}
                    options={refData.policyRecordStructure}
                    value={project.studyType === 'redi'
                      ? project.policyRecordStructure
                        ? refData.policyRecordStructure.find(
                          option => option.value ===
                            project.policyRecordStructure && project.policyRecordStructure.toString()
                        )
                        : ''
                      : ''
                    }
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={styles.eachItem} data-testid='calculateIBNR'>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={[styles.textHead, styles.unImpItem]}
                  >

                    <span>Calculate IBNR</span>

                    <Tooltip title="Optional - A date field that indicates when the claim was reported will be needed in the raw data in order to calculate IBNR.">
                      <InfoIcon sx={styles.iconColor} />
                    </Tooltip>

                    <span>:</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Select
                    data-testid='select-calculateIBNR'
                    name='calcIBNR'
                    styles={project.studyType === 'redi' ? styles.selected : styleObj}
                    isDisabled={!access || project.studyType !== 'redi'}
                    components={{ SingleValue }}
                    options={refData.yesNo}
                    value={project.studyType === 'redi'
                      ? project.calcIBNR !== ''
                        ? refData.yesNo.find(
                          option => option.value === project.calcIBNR.toString()
                        )
                        : ''
                      : ''
                    }
                    onChange={handleSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={styles.eachItem}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={[styles.textHead, styles.unImpItem]}
                  >
                    <span>Official Job ID</span>

                    <Tooltip
                      title="The ID of the job marked as the official job for this project"
                    >
                      <InfoIcon sx={styles.iconColor} />
                    </Tooltip>

                    <span>:</span>
                  </Typography>
                </Grid>
                <Grid
                  data-testid='label-officialJobId'
                  item
                  xs={6}
                  display="inline-flex"
                  alignItems="flex-end"
                >
                  {project.officialJobId ? project.officialJobId : 'N/A'}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid container direction="column">
          <Grid container direction="row" sx={styles.eachItem}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={styles.textHead}>
                <Icon
                  color="primary"
                  sx={styles.icon}
                >*</Icon>

                <span>Project Date:</span>
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <div style={styles.dateShade}>
              {isSmallScreen
                ? (
                <MobileDatePicker
                  disabled={!access}
                  variant="outlined"
                  format="MM/dd/yyyy"
                  name="mobile-datepicker"
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  value={new Date(project.projectDate)}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      sx: {
                        '& .MuiInputBase-input': {
                          fontSize: '13px',
                          margin: '15px 1px 15px 1px',
                          padding: '2px',
                          textAlign: 'center'
                        }
                      }
                    }
                  }}
                />
                  )
                : (
                <DatePicker
                  disabled={!access}
                  variant="outlined"
                  format="MM/dd/yyyy"
                  name="datepicker"
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  value={new Date(project.projectDate)}
                  onChange={handleDateChange}
                />
                  )}
              </div>
            </Grid>
          </Grid>

          <Grid sx={styles.linrBreak}></Grid>

          <Grid
            container
            direction="row"
            sx={[styles.eachItem, styles.unImpItem]}
          >
            <Typography
              variant="h6"
              sx={[styles.textHead, styles.paddingTop]}
            >Share Project</Typography>

            <Tooltip title={sharedWithIds}>
              <IconButton
                data-testid='iconButton-shareProject'
                disabled={!access || !project.id}
                aria-label="share Project"
                component="span"
                sx={styles.disp}
                onClick={handleIconButtonClick}
                size="large"
              >
                <SupervisorAccountIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

InputQuestions.propTypes = {
  onOpen: PropTypes.func,
  handleSelectChange: PropTypes.func
}

export default React.memo(InputQuestions)
