const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] === a[orderBy]) {
    return 0
  }

  if (b[orderBy] === null) {
    return -1
  }

  if (a[orderBy] === null) {
    return 1
  }

  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  return array
    .map((el, index) => [el, index])
    .sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    .map((el) => el[0])
}

function compareArrays (arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false
  }

  return arr1.every((element, index) => {
    return element === arr2[index]
  })
}

function getTestIdProp (props) {
  if (!props) {
    return {};
  }

  return Object.prototype.hasOwnProperty.call(props, 'data-testid') && typeof props['data-testid'] === 'string' ? { 'data-testid': props['data-testid'] } : {}
}

function determineMessage (files, location) {
  const messages = {
    edp: {
      '/prep': 'EDP views can not be manipulated with data prep',
      '/columnmapping': 'Column mapping is automatically done for EDP views',
      '/valuemapping': 'Value mapping is automatically done for EDP views'
    },
    dataFabric: {
      '/prep': 'Fabric datasets can not be manipulated with data prep',
      '/columnmapping': 'Column mapping is automatically done for Fabric datasets',
      '/valuemapping': 'Value mapping is automatically done for Fabric datasets'
    }
  }

  const dataSrc = files.find(file => file.src)?.src
  return messages[dataSrc]?.[location] ?? 'No Files Processed Successfully'
}

export {
  descendingComparator,
  getComparator,
  stableSort,
  compareArrays,
  getTestIdProp,
  determineMessage
}
